import useCustomHook from '@/hooks/useCustomHook';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useScrollContext() {
  const router = useRouter();

  const { state, actions } = useCustomHook({
    defaultState: {
      scrollIds: {},
    },
    actions: {
      publicActions: {
        setScrollId: (name, conf) => {
          const scrollIds = {
            ...state.scrollIds,
            [name]: conf,
          };
          actions.setState({ scrollIds });
        },
      },
    },
  });

  useEffect(() => {
    if (router.isReady) {
      for (const [name, conf] of Object.entries(state.scrollIds)) {
        if (
          conf &&
          !conf.keepIn.some((path) => router.asPath.startsWith(path))
        ) {
          console.log('clearing scroll id', name);
          actions.publicActions.setScrollId(name, null);
        }
      }
    }
  }, [router.isReady, router.asPath]);

  return { state, actions: actions.publicActions };
}

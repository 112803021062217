import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/shared/GlobalContext/GlobalContext';
import PlausibleProvider, { usePlausible } from 'next-plausible';
import { useRouter } from 'next/router';

const isDev = process.env.NODE_ENV == 'development';

export default function PlausibleAnalytics({ children }) {
  const globalContext = useContext(GlobalContext);
  const router = useRouter();
  const plausible = usePlausible();
  const [firstPageviewSent, setFirstPageviewSent] = useState(false);

  useEffect(() => {
    const sendPageview = () => {
      const usertype = getUserType(globalContext.state.user);
      plausible('pageview', { props: { usertype } });
    };

    if (globalContext.state.loaded && !firstPageviewSent) {
      setFirstPageviewSent(true);
      sendPageview();
    }

    router.events.on('routeChangeComplete', sendPageview);
    return () => {
      router.events.off('routeChangeComplete', sendPageview);
    };
  }, [globalContext.state.loaded, globalContext.state.user, router.events]);

  return (
    <PlausibleProvider
      domain={isDev ? 'localhost' : 'rockalingua.com'}
      customDomain="https://plausible.rockalingua.com"
      selfHosted
      trackLocalhost
      enabled
      manualPageviews
    >
      {children}
    </PlausibleProvider>
  );
}

export function getUserType(user) {
  let type = '';
  if (user?.student) {
    type = 'student';
  } else if (user?.teacher) {
    type = 'teacher';
  } else if (user?.admin) {
    type = 'admin';
  }
  return type;
}
